<template>
  <base-form
    v-slot="slotProps"
    :model="newsItem"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Neuigkeit"
  >
    <el-form-item label="Titel" prop="title">
      <el-input v-model="slotProps.model.title" v-focus></el-input>
    </el-form-item>

    <text-editor
      v-model="slotProps.model.content"
      :loading="loading"
      :allow-lists="false"
      :allow-headings="false"
      :allow-images="true"
      @image-uploaded="addImage"
    ></text-editor>

    <el-form-item label="Veröffentlichungsdatum" prop="published_at">
      <el-date-picker
        v-model="slotProps.model.published_at"
        format="dd.MM.yyyy"
        :picker-options="pickerOptions"
        type="date"
        placeholder="Veröffentlichungsdatum"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="Ablaufdatum" prop="unpublished_at">
      <el-date-picker
        v-model="slotProps.model.unpublished_at"
        format="dd.MM.yyyy"
        :picker-options="pickerOptions"
        type="date"
        placeholder="Veröffentlichungsdatum"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="Neuigkeit anpinnen (oben halten)">
      <el-switch
        v-model="slotProps.model.sticky"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>
  </base-form>
</template>

<script>
import { startOfToday, startOfTomorrow } from "date-fns"
import BaseForm from "@/components/forms/BaseForm"
import TextEditor from "@/components/TextEditor"

export default {
  components: {
    BaseForm,
    TextEditor
  },
  props: {
    newsItem: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: "Bitte Titel angeben", trigger: "blur" }
        ],
        published_at: [
          { required: true, message: "Bitte Datum angeben", trigger: "change" }
        ]
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", startOfToday())
            }
          },
          {
            text: "Morgen",
            onClick(picker) {
              picker.$emit("pick", startOfTomorrow())
            }
          }
        ]
      }
    }
  },
  computed: {},
  created() {},
  methods: {
    addImage({ signed_id, command }) {
      this.$emit("image-uploaded", {
        newsItem: this.newsItem,
        signed_id: signed_id,
        command: command
      })
    }
  }
}
</script>
